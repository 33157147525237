import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Title2, Title3, Large, colors } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { Helmet } from 'react-helmet';
import { PageTitle, PageTitle2, PageTitle3 } from '../../components/PageTitle';
import PercentLinks from '../../components/PercentLinks';

import ConversationCloud from '../images/conversationCloudDiagram.svg';

import LayerConnector from '../images/LayerConnector.svg';
import LayerGlobal from '../images/layerGlobal.svg';
import LayerTelephony from '../images/layerTelephony.svg';
import LayerWorkflow from '../images/layerWorkflow.svg';
import ActionEvent from '../images/actionEvent.svg';
import { CallToAction, GetStarted } from '../../components/CallToAction'


import Default from '../../components/Layouts/Default';


export default class RevenueProcessAutomation extends Component {

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | Hyperautomation vs. Sales Tools</title>
          <meta
            name="description"
            content="How does the hyperautomation approach stand out?"
          />
        </Helmet>

        <WrapperBackground color={colors.trulyDark} background="boardroom"  gradientType="purpleToGreen">
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
                Don't Make Hated Tasks Easier
              </PageTitle2>
              <PageTitle color={colors.white} mb="1.5rem" center>
                Eliminate Them Completely With Hyperautomation
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" py={['6rem', '3rem']} background={colors.trulyDark}>
            <Box width={[1, 2 / 3]}>
              <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                Redefining The Modern Sales Job
              </PageTitle2>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                 Win rates are down 15% across all industries compared to five years ago, and less than one quarter of reps are hitting quota.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                 <b>Why is that?</b>
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                 Pull up your reps' calendars - what percentage of their time is actually spent on selling?  According to Gartner, it's less than half due to the combination of CRM updates, forecasting, process training and many other admin tasks.  
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Ask your managers how confident they feel in their execution quality - is every deal getting the coaching attention it needs or do they feel like they're on a hamster wheel?  Best case, they're only able to review 1/3 of deals in pipeline and listen to 6% of call recordings.
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">              
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
               <b> The problem?</b>
              </Large>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Although sales tools are making reps more efficient, they're not making them efficient ENOUGH. These tools perpetuate the 'old way' of doing things, forcing reps to juggle four jobs at once, two of which aren't customer centric (crm updates, forecasting, market intelligence).  They're helping you build faster horses, not giving you the electric car.


              </Large>

            </Box>
            <br/><br/><br/>
           <PageTitle2 as="h2" color={colors.trulyBlue} bold my="1.5rem">
                Our Solution: Hyperautomation
           </PageTitle2>
            <br/>
            <Box width={[1, 3/3]} mb={['32px', 0]}>
              <CenteredImg src={ActionEvent} alt="Salesforce process automation"/>

            </Box>
            <br/><br/><br/>
            <Box width={[1, 2/3]} mb={['32px', 0]}>
 
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                With Hyperautomation, you can eliminate complex/repetitive administrative tasks for both reps and managers, enabling them to focus on what matters.
                <br/><br/><b>Helping customers and closing business.</b>
              </Large>
            </Box>

            <br/><br/><br/>
          </Section>

        </WrapperSolid>

       <WrapperBackground background="wave" gradientType="dark">
        <PercentLinks/>
      </WrapperBackground>

        <WrapperBackground background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>
        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const Values = styled.div`
  background-color: #222;
  font-family: 'Josefin Sans';
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
`;

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`;
